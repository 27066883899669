import React, { lazy, useCallback, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGoogleGeoCodeApi, getNotificationStatusApi } from "./api/user";
// import { onMessageListener } from "./utils/firebase";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

const token = "123";

function App() {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const access_token = localStorage.getItem("access_token");
  const [notifications, setNotifications] = useState(null);
  const [notificationsArr, setNotificationsArr] = useState([
    { notifications: "" },
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // onMessageListener()
  //   .then((payload) => {
  //     const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  //     const myCustomEvent = new CustomEvent("myCustomEvent", {
  //       detail: {
  //         notification: payload?.notification?.body,
  //         isNotified: userInfo?.is_notified,
  //       },
  //     });
  //     window.dispatchEvent(myCustomEvent);
  //     getNotificationStatus();
  //     const notificationObj = {
  //       notification: payload?.notification?.body,
  //       isNotified: userInfo?.is_notified,
  //     };
  //     const myCustomEventN = new CustomEvent("myCustomNotifications", {
  //       detail: {
  //         notifications: notificationObj,
  //       },
  //     });
  //     window.dispatchEvent(myCustomEventN);
  //   })
  //   .catch((err) => {
  //     toast.error(err);
  //   });

  useEffect(() => {
    if (access_token) {
      getNotificationStatus();
      const intervalId = setInterval(() => {
        getNotificationStatus();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [access_token]);

  const getNotificationStatus = async () => {
    const res = await getNotificationStatusApi();
    if (res?.status === 200) {
      const myCustomEventN = new CustomEvent("myCustomNotificationCount", {
        detail: {
          NotificationCount: res?.data?.count,
        },
      });
      window.dispatchEvent(myCustomEventN);
      localStorage.setItem("NotificationCount", res?.count);
    } else {
    }
  };

  useEffect(() => {
    let intervalId;
    if (isVisible) {
      intervalId = setInterval(() => {
        if (currentIndex < notificationsArr.length) {
          const currentItem = notificationsArr[currentIndex];
          if (currentItem?.notifications !== "") {
            toast.info(currentItem?.notifications, {
              autoClose: 5000,
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              className: "info",
              bodyClassName: "toast-body",
              progressClassName: "toast-progress",
              style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
              },
            });
          }
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(intervalId);
          setNotificationsArr([]);
        }
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [isVisible, currentIndex, notificationsArr]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setIsVisible(false);
      console.log("Page is now hidden");
    } else {
      setIsVisible(true);
      console.log("Page is now visible");
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setNotifications(event?.detail);
    };
    window.addEventListener("myCustomEvent", handleCustomEvent);
  }, [window.addEventListener]);

  useEffect(() => {
    let arr = [...notificationsArr];
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event?.data?.notification) {
        const notificationssss = event?.data?.notification.body;
        const n = { notifications: notificationssss };
        arr.push(n);
        setNotificationsArr(arr);
      }
    });
  }, [navigator, notificationsArr]);

  useEffect(() => {
    localStorage.setItem("theme", "business");
    themeChange(false);
  }, []);

  useEffect(() => {
    if (navigator.geolocation && access_token) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          });
          localStorage.setItem("latitude", position?.coords?.latitude);
          localStorage.setItem("longitude", position?.coords?.longitude);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  }, [access_token]);

  useEffect(() => {
    if (access_token) {
      const getGeocodeAddress = async () => {
        const res = await getGoogleGeoCodeApi(
          location?.latitude,
          location?.longitude
        );
        if (res?.status == "OK") {
          localStorage.setItem("address", res?.results[0]?.formatted_address);
        } else {
        }
      };
      getGeocodeAddress();
    }
  }, [location, access_token]);

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />

          {/* Place new routes over this */}
          <Route path="/*" element={<Layout />} />

          <Route
            path="*"
            element={<Navigate to={token ? "/home" : "/login"} replace />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;

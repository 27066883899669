export const register = async () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(function (registration) {
        registration.addEventListener("activate", function () {
          // Service worker is now active, subscribe to push notifications here
        });
      });
  }
};

import axios from "axios";
import { API_BASE_URL } from "../config";

const getLanguageFromLocalStorage = () => {
  // Replace 'i18nextLang' with the actual key you use to store language in local storage
  const storedLanguage = localStorage.getItem("i18nextLng");
  console.log(storedLanguage);
  // Modify logic to handle different cases
  switch (storedLanguage) {
    case "english":
      return "en";
    case "french":
      return "fr";
    // Add more cases as needed
    default:
      return "en"; // Default to English if language is not explicitly handled
  }
};

const setLanguageInHeaders = () => {
  const language = getLanguageFromLocalStorage();
  axios.defaults.headers.common["Accept-Language"] = language;
};

export const signUpApi = async (body, uuid) => {
  setLanguageInHeaders();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");

  const formData = new URLSearchParams();
  formData.append("name", encodeURIComponent(body.name));
  formData.append("email", body.email);
  formData.append("password", body.password);
  formData.append("user_type", encodeURIComponent(body.user_type));
  formData.append("device_token", encodeURIComponent(localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : ""));
  formData.append("device_type", encodeURIComponent("web"));
  formData.append("uuid", uuid);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}signup`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const login = async (body) => {
  setLanguageInHeaders();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : "");
  urlencoded.append("device_type", "web");
  urlencoded.append("email", body.email);
  urlencoded.append("password", body.password);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}login`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const anonymousUserApi = async (uuid) => {
  setLanguageInHeaders();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : "");
  urlencoded.append("device_type", "web");
  urlencoded.append("user_type", "user");
    urlencoded.append("uuid", uuid);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}web-user`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getManuByCatagoryApi = async (restaurant_id) => {
  setLanguageInHeaders();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("restaurant_id", restaurant_id);
  urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : "");
  urlencoded.append("device_type", "web");
  urlencoded.append("user_type", "user");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}menu_by_category`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const SocialLogin = async (body) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : "");
  
  urlencoded.append("device_type", "web");
  urlencoded.append("email", body.email);
  urlencoded.append("password", body.password);
  urlencoded.append("type", body.type);
  urlencoded.append("name", body.name);
  urlencoded.append("google_id", body.google_id);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}social-login`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const forgotPasswordApi = async (email) => {
  setLanguageInHeaders();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const formData = new URLSearchParams();
  formData.append("email", email);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}forgot-password`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token") ? localStorage.getItem("access_token") : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}profile-detail`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getNotificationsApi = async () => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}get-notifications`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getNotificationStatusApi = async () => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}get-notification-status
      `,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateProfile = async (body) => {
  try {
    setLanguageInHeaders();
    const response = await axios.post(`${API_BASE_URL}update-profile`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAdminsistrator = async (body) => {
  setLanguageInHeaders();
  const response = await axios.post(`${API_BASE_URL}deleteAdministrator`, body);
  return response;
};

export const changePassword = async (body) => {
  setLanguageInHeaders();
  const response = await axios.post(`${API_BASE_URL}change-password`, body);
  return response;
};

const uuid = localStorage.getItem("uuid");

export const logoutApi = async () => {
  setLanguageInHeaders();
  const token = localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : "";

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");

  const urlencoded = new URLSearchParams();
  urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
  ? localStorage.getItem("FCM-WEB")
  : "");
  urlencoded.append("device_type", "web");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}logout`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const searchRestaurantsAnddishes = async (activeTab, search , tokenValue ,fcmToken) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : fcmToken);
    urlencoded.append("device_type", "web");
    urlencoded.append("user_type", "user");
    urlencoded.append(
      "type",
      activeTab == "restaurant" ? "restaurant" : "menu_item"
    );
    urlencoded.append("search_term", search ? search : "");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_BASE_URL}search_in_restaurant`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const addToCartApi = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("is_scanned", dataObj.is_scanned);
    formData.append("menu_item_id", dataObj.menu_item_id);
    formData.append("quantity", dataObj.quantity);
    formData.append("sub_items", JSON.stringify(dataObj.sub_items));
    formData.append("tag_items", JSON.stringify(dataObj.tag_items));
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}v2/add-to-cart`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const restaurantDetailApi = async (restaurant_id) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`);

    const urlencoded = new URLSearchParams();
    urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    urlencoded.append("device_type", "web");
    urlencoded.append("user_type", "user");
    urlencoded.append("restaurant_id", restaurant_id);
    urlencoded.append("qr_code", "");

    const requestOptions = {
      // mode:CorsOptions,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    console.log(requestOptions, "options");
    const response = await fetch(
      `${API_BASE_URL}restaurant-detail?restaurant_id=${restaurant_id}`,
      { mode: "cors" },
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getCartItems = async () => {
  try {
    const urlencoded = new URLSearchParams();
    urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    urlencoded.append("device_type", "web");
    urlencoded.append("user_type", "user");

    const response = await fetch(`${API_BASE_URL}v2/get-cart`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateUserProfile = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("is_location_on", dataObj.is_location_on);
    formData.append("is_notified", dataObj.is_notified);
    formData.append("name", dataObj.name);
    formData.append("email", dataObj.email);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}update-profile`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteUserProfile = async () => {
  try {
    const urlencoded = new URLSearchParams();
    urlencoded.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    urlencoded.append("device_type", "web");
    urlencoded.append("user_type", "user");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: {},
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}delete-account`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateCartQuantityApi = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("tagquantity", dataObj.tagquantity);
    formData.append("quantity", dataObj.quantity);
    formData.append("sub_item_id", dataObj.sub_item_id);
    formData.append("cart_item_id", dataObj.cart_item_id);
    formData.append("subquantity", dataObj.subquantity);
    formData.append("tag_item_id", dataObj.tag_item_id);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}update-cart-quantity`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const removeCartItemApi = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("sub_item_id", dataObj.sub_item_id);
    formData.append("cart_item_id", dataObj.cart_item_id);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}v2/remove-cart-item`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const saveCardApi = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("card_number", dataObj.card_number);
    formData.append("card_expiry_month", dataObj.card_expiry_month);
    formData.append("card_expiry_year", dataObj.card_expiry_year);
    formData.append("card_cvv", dataObj.card_cvv);
    formData.append("country", dataObj.country);
    formData.append("name_on_card", dataObj.name_on_card);
    formData.append("email", dataObj.email);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");
    formData.append("mobile", dataObj.mobile);
    formData.append("opt_in_status", dataObj.opt_in_status);
    formData.append("country_code", "+1")
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(`${API_BASE_URL}save-card`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getSavedCardsApi = async (dataObj) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}get-saved-cards`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteCardApi = async (card_id) => {
  try {
    const formData = new URLSearchParams();
    formData.append("card_id", card_id);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}delete-card
      `,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const placeOrderApi = async (obj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("order_lat", obj?.order_lat);
    formData.append("order_lng", obj?.order_lng);
    formData.append("order_address", obj?.order_address);
    formData.append("order_type", obj?.order_type);
    formData.append("is_group", obj?.is_group);
    formData.append("number_of_member", obj?.number_of_member);
    formData.append("special_note", obj?.special_note);
    formData.append("table_number", obj?.table_number);
    formData.append("card_id", obj?.card_id);
    formData.append("payment_status", obj?.payment_status);
    formData.append("ordering_for", obj?.ordering_for);
    formData.append("order_id", obj?.order_id);
    formData.append("payment_gateway", obj?.payment_gateway);
    formData.append("transaction_id", obj?.transaction_id);
    formData.append("amount", obj?.amount);
    formData.append("client_email", obj?.client_email);
    formData.append("gratuity", obj?.gratuity);
    formData.append("gratuity_value", obj?.gratuity_value);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}v2/place-order
      `,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getOrderApi = async () => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}v2/get-orders`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getOrderDetailsApi = async (order_id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}v2/order-detail?order_id=${order_id}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const cancelOrderApi = async (order_id) => {
  try {
    const formData = new URLSearchParams();
    formData.append("order_id", order_id);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}cancel-order
      `,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const generateTicketApi = async (order_id, message) => {
  try {
    const formData = new URLSearchParams();
    formData.append("order_id", order_id);
    formData.append("message", message);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}generate-ticket
      `,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTicketApi = async (order_id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}get-ticket?order_id=${order_id}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyQrCodeApi = async (qr_code) => {
  try {
    const formData = new URLSearchParams();
    formData.append("qr_code", qr_code);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}verify-qr-code`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyTableApi = async (restaurant_id, qr_code) => {
  try {
    const formData = new URLSearchParams();
    formData.append("restaurant_id", restaurant_id);
    formData.append("qr_code", qr_code);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(`${API_BASE_URL}verify-table`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getGoogleGeoCodeApi = async (latitude, longitude) => {
  try {
    const formData = new URLSearchParams();
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(
      `${API_BASE_URL}get-google-geo-code?latitude=${latitude}&longitude=${longitude}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const payNowDirect = async (dataObj) => {
  try {
    const formData = new URLSearchParams();
    formData.append("longitude", dataObj);
    formData.append("latitude", dataObj);
    formData.append("device_token", localStorage.getItem("FCM-WEB")
    ? localStorage.getItem("FCM-WEB")
    : "");
    formData.append("device_type", "web");
    formData.append("user_type", "user");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : ""}`,
      },
      redirect: "follow",
    };
    const response = await fetch(`${API_BASE_URL}pay`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginObj:{},
    access_token:localStorage.getItem("access_token") ? localStorage.getItem("access_token") : ""
  },
  reducers: {
    onLoginAction: (state, action) => {
      state.loginObj = action.payload;
      // access_token = action?.payload?.access_token;
    },
    onFetchingToken:(state, action) => {
      state.access_token = action?.payload
    },
  },
});

export const { onLoginAction ,onFetchingToken } = loginSlice.actions;

export default loginSlice.reducer;
